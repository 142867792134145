<template>
    <div id="my-saved">
        <div class="my-save-list">
            <div class="my-save-item">
                <div class="avatar"></div>
                <div class="col">
                    <div class="row">
                        <span class="user-name">用戶MXS7528提問:</span>
                        <span class="gender">女</span>
                        <span class="age">32歲</span>
                    </div>
                    <div class="row">
                        <span class="category">#內科</span>
                        <span class="title">成日半夜成日毛啦啦醒一醒，然後又去訓反？</span>
                    </div>
                    <div class="desc">問題描述：後腦出汗問題頻繁，特別在夏天，經常是後腦沿住後髮際線滴</div>
                    <div class="fb-count">5條回復</div>
                </div>
            </div>
            <div class="my-save-item">
                <div class="avatar"></div>
                <div class="col">
                    <div class="row">
                        <span class="user-name">用戶MXS7528提問:</span>
                        <span class="gender">女</span>
                        <span class="age">32歲</span>
                    </div>
                    <div class="row">
                        <span class="category">#內科</span>
                        <span class="title">成日半夜成日毛啦啦醒一醒，然後又去訓反？</span>
                    </div>
                    <div class="desc">問題描述：後腦出汗問題頻繁，特別在夏天，經常是後腦沿住後髮際線滴</div>
                    <div class="fb-count">5條回復</div>
                </div>
            </div>
            <div class="my-save-item">
                <div class="avatar"></div>
                <div class="col">
                    <div class="row">
                        <span class="user-name">用戶MXS7528提問:</span>
                        <span class="gender">女</span>
                        <span class="age">32歲</span>
                    </div>
                    <div class="row">
                        <span class="category">#內科</span>
                        <span class="title">成日半夜成日毛啦啦醒一醒，然後又去訓反？</span>
                    </div>
                    <div class="desc">問題描述：後腦出汗問題頻繁，特別在夏天，經常是後腦沿住後髮際線滴</div>
                    <div class="fb-count">5條回復</div>
                </div>
            </div>
        </div>
		<div class="split-pages-container">
            <ol class="split-pages">
                <li class="split-page current">1</li>
                <li class="split-page">2</li>
                <li class="split-page">3</li>
                <li class="split-page">4</li>
                <li class="split-page more">…</li>
                <li class="split-page">12</li>
            </ol>
            <div class="btn-page-prev">上一頁</div>
            <div class="btn-page-next">下一頁</div>
        </div>
    </div>
</template>

<script>
	export default {
		mounted: function(){
		},
		data() {
            return {
            }
        },
        methods: {
        }
	};
</script>

<style lang="scss" scoped>
    #my-saved {
        position: relative;
        padding-bottom: 60px;
    }

    /* 我的收藏 star */
    .my-save-list {
        display: flex;
        flex-direction: column;
        padding: 0px 20px;
    }

    .my-save-list .my-save-item {
        padding-top: 10px;
        display: flex;
        flex-direction: row;
        box-sizing: border-box;
        border-bottom: 1px solid #e8e8e8;
    }

    .my-save-list .my-save-item .avatar {
        display: inline-block;
        width: 32px;
        height: 32px;
        background-image: url(../../../../assets/imgs/pc/user/img_yonghu@2x.png);
        background-size: 100% 100%;
        background-repeat: no-repeat;
    }

    .my-save-list .my-save-item .col {
        margin-left: 9px;
        flex: 1;
        display: flex;
        flex-direction: column;
    }

    .my-save-list .my-save-item .row {
        display: flex;
        flex-direction: row;
    }

    .my-save-list .my-save-item .user-name,
    .my-save-list .my-save-item .gender,
    .my-save-list .my-save-item .age {
        color: #969696;
        font-size: 14px;
        line-height: 13px;
        font-weight: 400;
    }

    .my-save-list .my-save-item .category,
    .my-save-list .my-save-item .title {
        color: #231F20;
        font-weight: bold;
        font-size: 18px;
        line-height: 18px;
        margin-top: 10px;
    }

    .my-save-list .my-save-item .category {
        color: #FF7800;
        margin-right: 10px;
    }

    .my-save-list .my-save-item .desc {
        color: #969696;
        font-size: 16px;
        line-height: 16px;
        margin-top: 10px;
        font-weight: 400;
    }

    .my-save-list .my-save-item .fb-count {
        color: #008C98;
        font-size: 14px;
        font-weight: 400;
        line-height: 13px;
        position: relative;
        padding-left: 29px;
        margin-top: 25px;
        margin-bottom: 11px;
    }

    .my-save-list .my-save-item .fb-count::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 17px;
        height: 15px;
        background-image: url(../../../../assets/imgs/pc/user/img_xiaoxii@2x.png);
        background-size: 100% 100%;
        background-repeat: no-repeat;
    }
    /* 我的收藏 end */

	.split-pages-container {
		position: absolute;
		bottom: 20px;
		right: 20px;
		display: flex;
		flex-direction: row;
		margin-top: 25px;
		justify-content: flex-end;
	}

	.split-pages-container > .split-pages {
		display: flex;
		flex-direction: row;
	}

	.split-pages-container > .split-pages > .split-page {
		display: inline-block;
		width: 22px;
		height: 22px;
		border-radius: 50%;
		color: #969696;
		font-size: 14px;
		line-height: 22px;
		text-align: center;
		font-weight: 400;
		margin-right: 20px;
		background-color: #DDDDDD;
		cursor: pointer;
	}

	.split-pages-container > .split-pages > .split-page.more {
		line-height: 16px;
	}

	.split-pages-container > .split-pages > .split-page:hover,
	.split-pages-container > .split-pages > .split-page.current {
		color: #fff;
		background-color: #36C4D0;
		font-weight: bold;
	}

	.split-pages-container > .btn-page-prev,
	.split-pages-container > .btn-page-next {
		height: 20px;
		width: 56px;
		text-align: center;
		line-height: 20px;
		font-weight: 300;
		color: #636363;
		background-color: #DDDDDD;
		border-radius: 4px;
		font-weight: 400;
		font-size: 14px;
		cursor: pointer;
	}

	.split-pages-container > .btn-page-next {
		margin-left: 24px;
		color: #231F20;
	}
</style>
